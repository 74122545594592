<template>
    <v-main class="system_main_background_color">
        <v-container fluid v-show="showTab">
			<v-row class="system_toolbar system_toolbar_color d-flex justify-content-between">
				<v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
                    <v-img :src="checkImagePath(GET_PREFERENCES.find(x => x.parameter == 'system_toolbar_image')?.value ?? null)" max-height="50" max-width="190" style="margin-left: -5px;"/>
                    <v-dialog max-width="1000" v-model="eventFormDialog" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="log_in_button_text_color" rounded style="margin-left: auto; margin-top: 10px;"
                                plain  v-bind="attrs" v-on="on">
                                <v-icon size="25" left style="margin-top: -10px;">
                                    mdi-draw-pen
                                </v-icon>
                                <span class="underline-animation system_font_family_primary fillup-button-textsize system_main_label_color" style="margin-top: -10px; font-size: 1rem; font-weight: bold;">
                                    Log in
                                </span>
                            </v-btn>
                        </template>
                    </v-dialog>
				</v-tabs>
			</v-row>
		</v-container>
        <v-container v-if="isDesktop" fluid>
            <v-row  class="system_toolbar system_toolbar_color d-flex w-auto justify-content-between ">
                <div>
                    <v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
                        <v-img :src="checkImagePath(GET_PREFERENCES.find(x => x.parameter == 'system_toolbar_image')?.value ?? null)" max-height="50" max-width="190" style="margin-left: -5px;"/>
                    </v-tabs>
                </div>
                <v-dialog max-width="1000" v-model="eventFormDialog" persistent>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="black"  rounded 
                            plain  v-bind="attrs" v-on="on">
                            <v-icon size="30" left class="system_main_label_color">
                                mdi-draw-pen
                            </v-icon>
                            <span class="underline-animation system_font_family_primary fillup-button-textsize system_main_label_color" style=" font-size: 1.1rem; font-weight: bold;">
                                Log in to event
                            </span>
                        </v-btn>
                    </template>
                </v-dialog>
            </v-row>
        </v-container>
        <v-container fluid>
            <!-- <div class="d-flex justify-content-center" style="height: auto;">
                <v-dialog max-width="1000" v-model="eventFormDialog" persistent>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#17468f" icon rounded
                            fab plain  v-bind="attrs" v-on="on">
                            <v-icon size="30" left>
                                mdi-draw-pen
                            </v-icon>
                            <span class="underline-animation fillup-button-textsize" style="font-family: Evogria; font-size: 1.1rem; font-weight: bold;">
                                Log in to event
                            </span>
                        </v-btn>
                    </template>
                </v-dialog>
            </div> -->
            <v-row class="px-6">
                <v-col>
                    <v-img 
                        :src="checkImagePath(GET_PREFERENCES.find(x => x.parameter == 'system_landing_image')?.value ?? null)"
                        alt="Placeholder"
                        width="100%"
                        height="100%"
                    ></v-img>
                </v-col>
            </v-row>
            <v-expansion-panels v-model="showActivities">
                <v-expansion-panel>
                    <v-expansion-panel-header class="small-header system_expansion_color">
                        <span class="text-center system_font_family_primary system_expansion_color" style="font-size: 2rem; font-weight: bold;">
                            Activities
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="system_expansion_background">
                        <v-divider :thickness="50"></v-divider>
                        <ActivitiesCarouselComponentVue/>
                        <v-divider :thickness="50"></v-divider>                        
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels v-model="showSitemapDesktop">
                    <v-expansion-panel>
                        <v-expansion-panel-header class="small-header system_expansion_color">
                            <span class="text-center system_font_family_primary" style="font-size: 2rem; font-weight: bold;">
                                Event Sitemap
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content  style="font-size: 1.4rem; font-weight: bold;" class="justify-content-between system_font_family_primary system_expansion_background">
                            <v-divider :thickness="50"></v-divider>
                                <EventSitemapCarouselComponent/>                        
                            <v-divider :thickness="50"></v-divider>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            <div class="d-flex justify-content-center pa-4" style="height: auto;">
                <v-dialog max-width="1000" v-model="eventFormDialog" persistent>
                    <!-- <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#17468f" icon rounded
                            fab plain v-bind="attrs" v-on="on">
                            <v-icon size="30" left>
                                mdi-draw-pen
                            </v-icon>
                            <span class="underline-animation" style="font-family: Evogria; font-size: 1.1rem; font-weight: bold;">
                                Log in to event
                            </span>
                        </v-btn>
                    </template> -->
                    <v-card class="border_dotted_log_out system_font_family_primary system_main_background_color" style="overflow-x:hidden;">
                        <v-card-title class="justify-content-between">
                            <span style="margin-left: auto;">Event Form</span>
                            <v-btn @click="closeEventForm" plain text icon color="white" style="margin-left: auto;">
                                <v-icon class="btn-action" color="white">mdi-window-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text v-if="settings.data_grouping == 1">
                            <div class="flex-container adaptive-text">
                                <div class="align-start" style="margin-top: 17px;">Reference #: &nbsp;</div>
                                <v-text-field style=" margin-top: 27px;" class="adaptive-text"
                                autofocus
                                @keyup.enter="submitCredentials"
                                color="#17468f"
                                clearable
                                :items="employeeDatas"
                                item-text="employee_id"
                                item-value="employee_id" v-model="employeeId" ></v-text-field>
                            </div>
                        </v-card-text>
                        <v-card-text v-if="settings.data_grouping == 2" class="pa-0">
                                <!-- <div class="align-start" style="margin-top: 17px;">Company: &nbsp;</div> -->
                                <v-row >
                                    <v-col cols="12" class="d-flex pa-0 px-7 pt-5 mb-2">
                                        <v-autocomplete
                                            v-model="company_id"
                                            @keyup.enter="filterGuests()" 
                                            @change="filterGuests()"
                                            :items="company_items"
                                            :search-input.sync="company_search"
                                            :loading="company_loading"
                                            cache-items
                                            label="Company"
                                            item-text="name"
                                            item-value="id"
                                            color="#EC6A30FF"	
                                            class="fill-width"
                                            hide-no-data
                                            hide-details
                                            persistent-hint
                                            regular
                                            tile
                                            outlined
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" class="d-flex pa-0 px-7 pb-4">
                                        <v-autocomplete
                                            v-if="companySearched"
                                            v-model="employeeId"
                                            :loading="loading"
                                            :items="guest_items"
                                            :search-input.sync="search"
                                            @change="getWithGuest()"
                                            cache-items
                                            item-text="full_name"
                                            item-value="employee_id"
                                            label="ENTER GUEST DETAILS"
                                            color="#EC6A30FF"	
                                            class="fill-width"
                                            hide-no-data
                                            persistent-hint
                                            tile
                                            outlined		
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <!-- <v-col cols="12" class=" pa-0 px-7 mb-2 pb-3" v-if="employeeId">
                                        <v-row style="height: 200px; overflow:auto" no-gutters class="pa-2" v-if="with_guest_items.length > 0 && !list_loading.with_guest">
                                            <span class="mb-1 mt-2">
                                                Select from the following people that are with you
                                            </span>
                                            <span v-for="(guest, i) in with_guest_items" :key="i" >
                                                <v-checkbox
                                                    @click="selectGuest(guest)"
                                                    :label="guest.full_name"
                                                    color="#17468f"
                                                    value="orange"
                                                    hide-details
                                                ></v-checkbox>
                                            </span>
                                        </v-row>
                                        <v-row v-else-if="list_loading.with_guest">
                                            <v-col cols="12" sm="12" class="text-center">
                                                <v-progress-circular
                                                    indeterminate
                                                    color="#17468f"
                                                    size="100"
                                                    width="8"
                                                ></v-progress-circular>
                                            </v-col>
                                        </v-row>
                                    </v-col> -->
                                </v-row>
                                <!-- <v-text-field style=" margin-top: 27px;" class="adaptive-text"
                                    autofocus
                                    @keyup.enter="submitCredentials"
                                    color="#17468f"
                                    clearable
                                    :items="employeeDatas"
                                    item-text="employee_id"
                                    item-value="employee_id" v-model="employeeId" 
                                >
                                </v-text-field> -->
                        </v-card-text>
                        <v-divider :thickness="20"></v-divider>
                        <span v-if="settings.data_grouping == 1">
                            <v-card-text>
                                <div class="flex-container adaptive-text">
                                    <div class="align-start" style=" margin-top: 5px;">Name: &nbsp;</div>
                                    <v-text-field style=" margin-top: 21px;" class="adaptive-text white-text-field" dense disabled v-model="selectedEmployeeName" color="#17468f"></v-text-field>
                                </div>
                            </v-card-text>
                            <v-card-text>
                                <div class="flex-container adaptive-text">
                                    <div class="align-start" style="margin-top: 5px;">Company: &nbsp;</div>
                                    <v-text-field style="margin-top: 21px;" class="adaptive-text white-text-field" dense disabled v-model="selectedEmployeeDepartment" color="#17468f"></v-text-field>
                                </div>
                            </v-card-text>                       
                        </span>
                        <v-divider :thickness="20"></v-divider>
                        <div class="d-flex justify-content-center">
                            <v-btn @click="submitCredentials" :disabled="employeeId ? false : true" class="submit_button_text_color" plain dark >
                                Submit
                            </v-btn>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </v-container>          
    </v-main>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import { mapGetters } from 'vuex';
import ActivitiesCarouselComponentVue from '@/components/expansions/ActivitiesCarouselComponent.vue';
import EventSitemapCarouselComponent from '@/components/expansions/EventSitemapCarouselComponent.vue';
import moment from 'moment';
export default {
    data() {
        return {
            last_name: '',
            first_name: '',
            showActivities: -1,
            showSitemapDesktop: -1,
            middle_name: '',
            employeeDatas: [],
            eventFormDialog: false,
            deptID: null,
            companyID: null,
            employeeDataLocalID: null,
            employeeId: null,
            department: '',
            isDesktop: window.innerWidth > 1000,
            showTab: window.innerWidth <= 1000,
            data: [],
            items: [
                {
                src: require('@/assets/banner.jpg.png'),
                },
                {
                src: require('@/assets/banner.jpg.png'),
                },
                {
                    src: require('@/assets/banner.jpg.png'),
                },
            ],
            currentItem: 0, 
            settings: {
                data_grouping: null,
            },
            dropdowns:{
                company_list: []
            },
            company_id: null,
            companySearched: false,
            selected_company: {},
            guest_items: [],
            search: '',
            loading: false,
            company_search: '',
            company_items: [],
            company_loading: false,
            withGuestID: [],
            with_guest_search: '',
            with_guest_items: [],
            list_loading:{
                with_guest: false
            },
            all_guest: []
        };
    },
    computed: {
        ...mapGetters([
				"GET_NEW_MASTER_DATA",
                "GET_PREFERENCES",
                "GET_EVENT_ID",
                "GET_EVENT_NAME"
			]),
        selectedEmployee() {
            return this.employeeDatas.find(employee => employee.employee_id === this.employeeId) || { full_name: '', company_name: '', employee_data_local_id: '', department_id: '', employment_duration_label: ''};
        },
        selectedEmployeeName() {
            return this.selectedEmployee.full_name;
        },
        selectedEmployeeDepartment() {
            this.employeeDataLocalID = this.selectedEmployee.employee_data_local_id;
            this.deptID = this.selectedEmployee.department_id;
            this.companyID = this.selectedEmployee.company_name;
            this.employment_duration_label = this.selectedEmployee.employment_duration_label;
            return this.selectedEmployee.company_name;
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
		// this.getGroupingSettings();
		// this.getCompanyDropdowns();
	},
    components:{
		EventSitemapCarouselComponent,
        ActivitiesCarouselComponentVue
	},
	beforeDestroy() {
	    window.removeEventListener('resize', this.handleResize);
	},
    created() {
        this.showActivities = 0;
        this.showSitemapDesktop = 0;
    },
    methods: {
        handleResize() {
            this.isDesktop = window.innerWidth > 1000;
            this.clicked = this.isDesktop;
            this.clicked = !this.clicked;
            this.showTab = window.innerWidth <= 1000;
	    },
        closeEventForm() {
            this.employeeId = null;
            this.companyID = null;
            this.company_id = null;
            this.withGuestID = [];
            this.eventFormDialog = !this.eventFormDialog;
            this.companySearched = false;
        },
        async fetchData() {
            let payload = {
                url: "submit-credentials",
                id: this.data,
                event_id: this.GET_EVENT_ID
            };

            try {
            // let response = await axios.get(process.env.VUE_APP_API_HOST + '/api/submit-credentials');
                this.$store.dispatch("urlGet",  payload).then((res)=>{
                this.employeeDatas = res.allEmployeeData;
                })
                this.$store.commit('NEW_MASTER_DATA', true);
            } catch (error) {
                console.error(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to fetch data!',
                });
            }
        },
        async submitCredentials() {
            Swal.fire({
                title: 'Please Wait',
                allowOutsideClick: false,
                showCancelButton: false,
                showConfirmButton: false,
            });

            if (!this.employeeId) {
                Swal.fire({
                    icon: 'error',
                    title: 'Please Input',
                    allowOutsideClick: false,
                    text: `Please enter ${this.settings.data_grouping == 1 ? ' your reference number' : 'guest details'}.`,
                    customClass: {
                        confirmButton: 'orange-button',
                    }
                });
                return;
            }

            // if (this.employeeId.length !== 9) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Invalid Reference Number',
            //         allowOutsideClick: false,
            //         text: 'Reference Number should be 9 characters long.',
            //         customClass: {
            //             confirmButton: 'orange-button',
            //         }
            //     });
            //     return;
            // }

            Swal.showLoading();
            let employee_data = this.employeeDatas.find(x=> x.employee_id == this.employeeId);
            this.employeeDataLocalID = employee_data.employee_data_local_id;
            this.deptID = employee_data.department_id;
            this.data.push({
                employee_data_id: this.employeeDataLocalID,
                employee_id: this.employeeId,
                department_id: this.deptID,
                tenure_code_id: this.employment_duration_label,
                company_id: this.company_id,
            });
            localStorage.setItem('refNumber', this.employeeId);
            this.withGuestID = this.with_guest_items.filter((x)=> x.selected == true).map(e=> e.employee_id)
            let payload = {
                url: "selected-id",
                id: this.data,
                with_guest: this.withGuestID,
                company_id: this.company_id,
                event_id: this.GET_EVENT_ID
            };
            try {
                await this.$store.dispatch("urlPost", payload).then((res) => {
                    Swal.fire({
                        title: 'Processing',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    Swal.showLoading();
                    localStorage.setItem('token', res.token );
                    this.$store.commit('REMAINING_GUEST', res.remaining_guest)
                    this.$store.commit('REMIND_GUEST', 1)
                    Swal.close();
                    this.eventFormDialog = false;
                    this.$router.push('/welcome');
                });
            } catch (error) {
                if (error.response.status === 500) {
                    Swal.fire({
                        title: "<strong><u>Error</u></strong>",
                        text: error.response.data.message,
                        icon: 'warning',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'orange-button',
                        }
                    });
                }
            }

            this.data = [];
            this.employeeId = null;
        },
        font_size() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "15"
                case 'sm': return "15"
                case 'md': return "20"
                case 'lg': return "20"
                case 'xl': return "20"
            }
        },
        async getGroupingSettings(){
			let payload = {
                type: 'Expansions',
                url: 'moduleSettings',
            };
            await this.$store.dispatch("urlGet", payload).then((res)=>{
                res.forEach(e=>{
                    Object.keys(this.settings).filter(el=>{
                        if(el == e.parameter){
                            this.settings[el] = parseFloat(e.value)
                        }
                    });
                })
            })
		},
        getCompanyDropdowns(){
			let payload = {
				url: 'getAllCompany',
                event_id: this.GET_EVENT_ID
			}
			this.$store.dispatch('urlGet', payload).then((res)=>{
				this.dropdowns.company_list = res.data
			});
		},
        filterGuests(){
            this.guest_items = [];
			this.companySearched = true;
			this.selected_company = this.dropdowns.company_list.find((x)=> x.id == this.company_id);
			// this.selected_company.ticket_numbers = this.selected_company.company_tickets.map(x => x.ticket_num)
            this.employeeId = null;
		},
        querySelections (v) {
			this.loading = true
            let filtered = this.all_guest;
            let filtered_items = filtered.filter((x=>{
                if(x.full_name.match(v) && x.company_id == this.company_id){
                    return x;
                }
                else if (x.full_name.toLowerCase().indexOf(v) > -1 && x.company_id == this.company_id){
                    return x;
                }
            }));
            this.guest_items = filtered_items
            this.loading = false;

            // console.log(filtered)
			// let payload = {
			// 	url: 'getEmployeeSearch',
			// 	company_id: this.company_id,
			// 	search: this.search,
            //     event_id: this.GET_EVENT_ID
			// }
			// this.$store.dispatch('urlPost', payload).then((res)=>{
			// 	this.guest_items = res
			// 	this.loading = false
			// })
		},
        getWithGuest(){
            // this.list_loading.with_guest = true
            // let payload = {
			// 	url: 'getEmployeeSearch',
			// 	company_id: this.company_id,
            //     event_id: this.GET_EVENT_ID
			// }
			// this.$store.dispatch('urlPost', payload).then((res)=>{
            //     this.with_guest_items = res.filter(x => x.status_id == 1 && x.employee_id != this.employeeId);
            //     this.list_loading.with_guest = false
            //     // this.with_guest_items = res.filter((x) => x.employee_id != this.employeeId);
			// })
        },
        querySelectionsCompany (v) {
			this.company_loading = true
            let filtered = this.dropdowns.company_list;
            if(v){
                filtered = filtered.filter(item => item.name.toLowerCase().indexOf(v) > -1 );
                this.company_items = filtered
            }else{
                this.company_items = filtered
            }

			this.company_loading = false

			// let payload = {
			// 	url: 'searchCompany',
			// 	company_id: this.company_id,
			// 	search: this.search,
            //     event_id: this.GET_EVENT_ID
			// }
			// this.$store.dispatch('urlPost', payload).then((res)=>{
			// 	this.company_items = res
			// 	this.company_loading = false
			// })
		},
        selectGuest(item){
            item.selected = !item.selected
        },
        checkImagePath(image_path) {
            return process.env.VUE_APP_API_HOST + "/" + image_path
        },
        getAllGuest(){
            let payload = {
                url: 'getAllGuest',
                event_id: this.GET_EVENT_ID
            }
            this.$store.dispatch('urlPost', payload).then(res=>{
                this.all_guest = res.data
            })
        }
    },
    watch:{
        search (val) {
            if(!val){
				this.guest_items = []
            }else{
                val && val !== this.employeeId && this.querySelections(val)
            }
		},
        company_search:{
            handler(val){
                if(!val){
                    this.company_items = []
                }else{
                    try{
                        let filtered = this.dropdowns.company_list;
                        let filtered_items = filtered.filter((x=>{
                            if(x.name.match(val)){
                                return x;
                            }
                            else if (x.name.toLowerCase().indexOf(val) > -1){
                                return x;
                            }
                        }));
                        this.company_items = filtered_items
                    }catch(error){
                        alert(error)
                    }
                }
            },
            deep: true,
            immediate: true
		},
        employeeId (val){
			let guest_details = this.employeeDatas.find((x)=> x.employee_id == val);
			if(guest_details){
				this.selected_company.table_num = guest_details.table_num;
				this.selected_company.buffet_table = guest_details.buffet_meal_id;
				this.selected_company.guest_name = guest_details.full_name;
			}else{
				this.selected_company.table_num = '';
				this.selected_company.buffet_table = '';
				this.selected_company.guest_name = '';
			}
		},
        GET_EVENT_ID:{
            handler(val){
                this.fetchData();
                this.getGroupingSettings();
		        this.getCompanyDropdowns();
                this.getAllGuest();
            },
            immediate: true
        },
        GET_EVENT_NAME: {
            handler(val){
                document.title = `${val ? val.toUpperCase() : ''} ${moment().format('YYYY')}`;
            }
        }
    }
};
</script>

<style scoped>
    .transparent-btn { background-color: transparent; border: none; }
    .black-font { color: black !important; }
    .slide-enter-active, .slide-leave-active {
        transition: transform 0.5s;
    }
    #ingco-logo-img{
        height: 40px;
    }
    .slide-enter, .slide-leave-to {
        transform: translateX(-6%);
    }
    body {
        overflow: hidden;
    }
    .flex-container {
        display: flex;
        align-items: center;
    }
    .flex-container span {
        margin-right: 10px; 
    }
    .adaptive-text {
        font-size: 14px;
        justify-content: center;
    }
    @media (max-width: 600px) {
        .adaptive-text {
        font-size: 12px; 
        }
    }
    .responsive-img {
        height: 100%;
        width: 100%;
        display: block;
        margin: 0 auto;
    }
    @media (max-width: 600px) {
        .responsive-img {
            height: 100%;
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
    
    .fillup-button-textsize{
        font-size: 14px;
        justify-content: center;
    }

    .carousel-name-text-font {
        font-size: 50px; 
    }

    .carousel-desc-name-text-font {
        font-size: 40px; 
    }
    @media (max-width: 600px) {
        .fillup-button-textsize {
            font-size: 10px; 
        }

        .carousel-name-text-font {
            font-size: 30px; 
        }
         .carousel-desc-name-text-font {
            font-size: 20px; 
        }
    }
    .small-header {
        padding-top: 1px; /* Adjust as needed */
        padding-bottom: 1px; /* Adjust as needed */
    }
    body {
    background-color: black;
    }
       .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height:auto; /* Adjust this value as needed */

    }
    ::v-deep .white-text-field input {
        color: white !important;
      }
</style>

