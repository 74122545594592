<template>
	<v-main>
		<v-container fluid>
		<div v-show="showTab">
			<v-row class="system_toolbar system_toolbar_color d-flex w-auto justify-content-between">
				<v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
					<img src="@/assets/ingcoph_logo_alt.png" style="margin-left: -5px;" >
					<v-menu v-model="menuOpen" :absolute="false" offset-y class="black-font" max-width="auto" style="font-family: Evogria;">
						<template v-slot:activator="{ on, attrs }">
							<v-tab v-show="showTab" v-bind="attrs" v-on="on" @click="toggleMenu" color="transparent" dark plain class="black-font underline-animation-black" style="font-family: Evogria; margin-left: auto;">
								<svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 24 24">
									<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2">
									<path :d="!menuOpen ? 'M5 5L19 5' : 'M5 5L19 19'" />
									<path :d="!menuOpen ? 'M5 12H19' : 'M12 12H12'" />
									<path :d="!menuOpen ? 'M5 19L19 19' : 'M5 19L19 5'" />
									</g>
								</svg>
							</v-tab>
						</template>
						<div class="justify-content-between orange-border-dotted" style="width: auto">
							<v-card style="width: 300px; height: auto;">
								<v-list  class="orange-border align-center" color="black">
									<v-divider :thickness="50"></v-divider>
									<span style="font-family: Evogria; color: orange; font-size: 10px; padding-left: 10px;"> Welcome: &nbsp; {{ employeeName }} &nbsp; </span>
									<v-btn small plain @click="redirectToLandingPage" color="#17468f">
										<v-list-item-title style="font-family: Evogria; font-size: 11px; color:white;">Log Out</v-list-item-title>
									</v-btn>
									<v-divider :thickness="50"></v-divider>
								</v-list>
							</v-card>
						</div>
					</v-menu>
				</v-tabs>
			</v-row>
		</div>
		<v-row v-if="isDesktop" class="system_toolbar system_toolbar_color d-flex w-auto justify-content-between ">
			<div>
			<v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
				<img src="@/assets/ingcoph_logo_alt.png" style="margin-left: -5px;" >
			</v-tabs>
			</div>
			<div v-if="isDesktop" style="display: flex; margin-right: 1%">
				<span style="font-family: Evogria; color: black; font-size: small;" ><v-icon size="30" center color="black">mdi-account-check</v-icon> Welcome: &nbsp; {{ employeeName }} &nbsp; </span>
				<v-btn @click="redirectToLandingPage" small color="transparent" dark class="black-font underline-animation-black" style="font-family: Evogria;">
					Log-out
				</v-btn>
			</div>
		</v-row>
	</v-container>
	<v-expansion-panels v-show="showTab"  v-model="showInfoPanelMobile">
		<v-expansion-panel class="small-header">
			<v-expansion-panel-header color="black">
				<span class="text-center" style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
					Information
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-family: Evogria; font-size: 1rem; font-weight: bold;" class="justify-content-between">
				<v-card-title style="justify-content: center;" >
					Guest Event Information
				</v-card-title>
				<v-divider :thickness="50"></v-divider>
				<div >
					<div style="justify-content: center; font-family: Evogria;" class="adaptive-text">
						<v-card-text class="adaptive-text">
							<v-icon size="30" left>mdi-account-check</v-icon> Name: &nbsp; <span class="orange-text">{{ employeeName }}</span>
							<v-divider :thickness="50"></v-divider>
						</v-card-text>
						<v-card-text class="adaptive-text">
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
								<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
							</svg>Company: &nbsp; <span class="orange-text">{{ company_name }}</span>
							<v-divider :thickness="50"></v-divider>
						</v-card-text>
						<v-card-text class="adaptive-text">
							<v-icon size="30" left>mdi-ticket-confirmation-outline</v-icon> Reference Number: &nbsp; <span class="orange-text">#{{ referenceNumber }}</span>
							<v-divider :thickness="50"></v-divider>
						</v-card-text>
						<v-card-text class="adaptive-text">
							<v-icon size="30" left>mdi-chair-rolling</v-icon>Table Number: &nbsp; <span class="orange-text">Table {{ tableNumber }}</span>
							<v-divider :thickness="50"></v-divider>
						</v-card-text>
						<v-card-text class="adaptive-text">
							<v-icon size="30" left>mdi-silverware-variant</v-icon>Buffet Table: &nbsp; <span class="orange-text">Table {{ buffetTable }}</span>
							<v-divider :thickness="50"></v-divider>
						</v-card-text>
						<v-card-text class="adaptive-text">
							<v-icon size="30" left>mdi-glass-mug-variant</v-icon>Mobile Bar Ticket: &nbsp; <span class="orange-text">{{ mobileBarTicket }} / 2</span>
							<v-divider :thickness="50"></v-divider>
						</v-card-text>
						<v-card-text class="adaptive-text"><v-icon size="30" left>mdi-qrcode-plus</v-icon>QR Code: 
							<v-spacer></v-spacer>
							<div style="margin-bottom: 10px;"></div>
							<div style="font-size: 18px; display:flex; justify-content:center;">
								<img :src="qrImage" alt="QR Code" style="width: auto; height: auto;" />
							</div>
							<v-card-text style="font-style: italic; text-align: center; color: orange;">
								<span>"</span>
								<span class="Adaptive-text-Note text-center" style="text-decoration: underline;">Note: Please save QR code to show in mobile bar</span>
								<span>"</span>
								<v-divider :thickness="50"></v-divider>
							</v-card-text>
							<div  style="display: flex; justify-content: center;">
								<v-btn  small @click="downloadQR" class="orange-border underline-animation-black" @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }"  color="#17468f" dark>
									<span style="color: black;"><v-icon size="24" left>mdi-download-box</v-icon>Download QR</span>
								</v-btn>
							</div>
						</v-card-text>
					</div>
				</div>
				<v-divider :thickness="50"></v-divider>
			</v-expansion-panel-content>
			<v-expansion-panel>
				<v-expansion-panel-header color="black" class="small-header">
					<span class="text-center" style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
						Activities
					</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content color="black" style="font-family: Evogria; font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
					<v-divider :thickness="50"></v-divider>
					<div class="d-flex justify-content-center pa-4">
						<v-carousel hide-delimiters id="carousel" height="auto" style="width: 200%;" v-model="currentItem">
							<v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" cover></v-carousel-item>
						</v-carousel>
					</div>
					<v-divider :thickness="50"></v-divider>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel v-model="programFlowExpanded">
				<v-expansion-panel-header color="black">
					<span class="text-center" style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
						Program Flow
					</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content color="black" style="font-family: Evogria; font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
					<v-divider :thickness="50"></v-divider>
					<div style="width: 100%; height: 100%;">
						<v-carousel cycle interval="10000" :show-arrows="!['xs', 'sm'].includes($vuetify.breakpoint.name)" hide-delimiters id="carousel" height="auto">
                            <v-carousel-item v-for="(item, i) in flow_items" :key="i" style="">
                                <v-card
                                    tile
                                >
                                <v-img 
                                    :src="item.flow_image" 
                                    class="ma-0 white--text align-end"
                                    height="800"
                                >
                                    <v-card-title style="height:200px;">{{ item.description }}</v-card-title>
                                </v-img>
                                </v-card>
                            </v-carousel-item>
                        </v-carousel>   
						<!-- <v-img 
							src="@/assets/ingco_promo_landingv3.jpg"
							alt="Placeholder">
						</v-img> -->
					</div>
					<v-divider :thickness="50"></v-divider>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panel>
	</v-expansion-panels>
		<v-expansion-panels v-if="isDesktop"  v-model="showInfoPanel">
				<v-expansion-panel>
				<v-expansion-panel-header color="black">
					<span class="text-center" style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
						Information
					</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content color="black" style="font-family: Evogria; font-weight: bold;" class="justify-content-between">
					<v-card-title style="justify-content: center;" class="adaptive-text">
						Attendee Event Information
					</v-card-title>
					<v-divider :thickness="50"></v-divider>
					<div style="display: flex; justify-content: space-between;" >
						<div style="width: 50%; justify-content: center;" class="adaptive-text">
							<v-card-text class="adaptive-text">
								<v-icon size="40" left>mdi-account-check</v-icon> Name: &nbsp; <span class="orange-text">{{ employeeName }}</span>
								<v-divider :thickness="50"></v-divider>
							</v-card-text>
							<v-card-text class="adaptive-text">
								<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
									<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
								</svg> Company: &nbsp; <span class="orange-text">{{ company_name }}</span>
								<v-divider :thickness="50"></v-divider>
							</v-card-text>
							<v-card-text class="adaptive-text">
								<v-icon size="40" left>mdi-ticket-confirmation-outline</v-icon> Reference Number: &nbsp; <span class="orange-text">#{{ referenceNumber }}</span>
								<v-divider :thickness="50"></v-divider>
							</v-card-text>
							<v-card-text class="adaptive-text">
								<v-icon size="40" left>mdi-chair-rolling</v-icon>Table Number: &nbsp; <span class="orange-text">Table {{ tableNumber }}</span>
								<v-divider :thickness="50"></v-divider>
							</v-card-text>
							<v-card-text class="adaptive-text">
								<v-icon size="40" left>mdi-silverware-variant</v-icon>Buffet Table: &nbsp; <span class="orange-text">Table {{ buffetTable }}</span>
								<v-divider :thickness="50"></v-divider>
							</v-card-text>
							<v-card-text class="adaptive-text">
								<v-icon size="40" left>mdi-glass-mug-variant</v-icon>Mobile Bar Ticket: &nbsp; <span class="orange-text">{{ mobileBarTicket }} / 2</span>
								<v-divider :thickness="50"></v-divider>
							</v-card-text>
						</div>
						<div style="width: 50%;">
							<v-card-text class="d-flex align-start adaptive-text">
								<v-icon size="30" left>mdi-qrcode-plus</v-icon>
								<span>QR Code:</span>
								<v-spacer></v-spacer>
								<!-- <v-img 
								src="@/assets/qr.png"
								alt="Placeholder"
								max-width="40%"
								max-height="auto"
								style="float: center; margin-right: 30%;"
								></v-img> -->
								<img :src="qrImage" alt="QR Code" style="width: 350px; height: auto; float: center; margin-right: 30%;" />
							</v-card-text>
							<v-divider :thickness="50"></v-divider>
							<div class="d-flex flex-column text-center">
								<v-card-text style="font-style: italic; color: orange; ">
									<span>"</span>
									<span class="Adaptive-text-Note" style="text-decoration: underline;">Note: Please save QR code to show in mobile bar</span>
									<span>"</span>
								</v-card-text>
								<v-btn @click="downloadQR" class="orange-border underline-animation-black" @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }" color="#17468f" small dark>
									<span style="color: black;"><v-icon size="24" left>mdi-download-box</v-icon>Download QR</span>
								</v-btn>
							</div>
						</div>
					</div>
					<v-divider :thickness="50"></v-divider>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header color="black" class="small-header">
					<span class="text-center" style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
						Activities
					</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content color="black" style="font-family: Evogria; font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
					<v-divider :thickness="50"></v-divider>
					<div class="d-flex justify-content-center pa-4">
						<v-carousel hide-delimiters id="carousel" height="auto" style="width: 200%;" v-model="currentItem">
							<v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" cover></v-carousel-item>
						</v-carousel>
					</div>
					<v-divider :thickness="50"></v-divider>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel v-model="programFlowExpanded">
				<v-expansion-panel-header color="black">
					<span class="text-center" style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
						Program Flow
					</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content color="black" style="font-family: Evogria; font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
					<v-divider :thickness="50"></v-divider>
					<div style="width: 100%; height: 100%;">
					<v-img 
					src="@/assets/ingco_promo_landingv3.jpg"
					alt="Placeholder"
					></v-img>
					</div>
					<v-divider :thickness="50"></v-divider>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-main>
</template>

<script>
	import Swal from 'sweetalert2';
	import axios from 'axios';
	import { defineComponent } from 'vue';
	defineComponent({
	name: 'MdiCompany',
	name: 'MdiHashtag',
	});
	export default {
	name: 'Header',
	data() {
	return {
		showInfoPanel: -1,
		showInfoPanelMobile: -1,
		referenceNumber: null,
		employeeName: '',
		company_name: '',
		programFlowExpanded: true,
		showEventInformation: false,
		tableNumber: '',
		buffetTable: '',
		menuOpen: false,
		test: '',
		mobileBarTicket: '',
		qrImage: '',
		hovered: false,
		clicked: true,
		isDesktop: window.innerWidth > 1000,
		showTab: window.innerWidth <= 1000,
		items: [
		{
            src: require('@/assets/ingcoph_logo.jpg'),
		},
		{
            src: require('@/assets/banner.jpg.png'),
		},
        ],
        currentItem: 0, 
		flow_items: []
    };
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
		this.getProgramFlowImages();
	},
	beforeDestroy() {
	window.removeEventListener('resize', this.handleResize);
	},
	created() {
	document.title = 'Welcome';
	this.startCarouselAutoAdvance();
	this.submitEmployeeId();
	},
	beforeDestroy() {
		this.stopCarouselAutoAdvance();
	},
	methods: {
	redirectToLandingPage() {
		localStorage.removeItem("refNumberConfirmation");
		this.$router.push('/ConfirmationHome');
		this.reloadPage();
	},
	toggleSheet() {
		this.$emit('toggle-sheet');
	},
	reloadPage() {
		window.location.reload();
	},
	handleResize() {
		this.isDesktop = window.innerWidth > 1000;
		this.clicked = this.isDesktop;
		this.clicked = !this.clicked;
		this.showTab = window.innerWidth <= 1000;
	},
	toggleSVG() {
		this.clicked = !this.clicked;
	},
	toggleMenu() {
		this.menuOpen = !this.menuOpen;

	},
	async submitEmployeeId() {
		this.referenceNumber = localStorage.getItem("refNumberConfirmation");
		Swal.showLoading();
		try {
			await axios.post(process.env.VUE_APP_API_HOST + '/api/check-employee-bar_register', {
			employee_id: this.referenceNumber
			}).then((response)=>{
			this.employeedData = response.data.remaining_tickets;
			if (response.data.message === "Pre-Registration is complete!") { 
				this.tableNumber = response.data["0"].table_num;
				this.buffetTable = response.data["0"].buffet_meal_id;
				this.mobileBarTicket = response.data["0"].mobile_bar_count;
				this.employeeName = response.data["0"].full_name;
				this.company_name = response.data["0"].company_name;
				Swal.fire({
				title: 'Welcome',
				html: ` <br> ${response.data["0"].full_name}
				<br> of <br> ${response.data["0"].company_name}`,
				icon: 'success',
				allowOutsideClick: false,
				customClass: {
					confirmButton: 'orange-button',
				},
				}).then((result) => {
				if (result.isConfirmed) {
					this.showInfoPanel = 0;
					this.showInfoPanelMobile = 0;
					this.generateQR(this.referenceNumber);
					this.showEventInformation = true;
				}
				});
			}
			});
			}catch (error) {
			if (error.response.status === 500) {
			Swal.fire({
				title: "<strong><u>NOT FOUND</u></strong>",
				text: error.response.data.message, // Access the message property from the response data object
				icon: 'warning',
				allowOutsideClick: false,
				customClass: {
				confirmButton: 'orange-button',
				}
			});
			} else {
			console.error(error);
			}
		}
		},
		async generateQR(employee_id) { 
		Swal.showLoading();
			try {
				const response = await axios.post(process.env.VUE_APP_API_HOST + '/api/generate_QR', {
					employee_id: employee_id
				});
				const svgDataUri = `data:image/svg+xml;base64,${btoa(response.data)}`;
		this.qrImage = svgDataUri;
		} catch (error) {
		console.error(error);
		}
		},
		async downloadQR() {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		const img = new Image();
		img.onload = () => {
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0);
			const link = document.createElement('a');
			link.href = canvas.toDataURL('image/png');
			link.download = `${this.employeeName}_QR_Code.png`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			Swal.fire({
					title: 'CHECK YOUR DOWNLOADS!',
					text: 'Your QR Code has been downloaded successfully!',
					icon: 'success',
					allowOutsideClick: false,
					customClass: {
					confirmButton: 'orange-button',
					}
					})
		};
		img.src = this.qrImage;
		},
		startCarouselAutoAdvance() {
		this.carouselTimer = setInterval(() => {
			this.currentItem = (this.currentItem + 1) % this.items.length;
		}, 10000); // Advance every 5 seconds (5000 milliseconds)
		},
		stopCarouselAutoAdvance() {
		clearInterval(this.carouselTimer);
		},
		reset() {
		this.currentItem = 1;
		},
		getProgramFlowImages(){
			let payload = {
				url: 'getProgramFlowImages'
			}
			this.$store.dispatch('urlGet', payload).then((res)=>{
				this.items = res.data
			});
		}
	},
	}
</script>

<style>
	.transparent-btn { background-color: transparent; border: none; }
	.black-font { color: black !important; }
	.slide-enter-active, .slide-leave-active {
	transition: transform 0.5s;
	}
	#ingco-logo-img{
	height: 40px;
	}
	.slide-enter, .slide-leave-to {
	transform: translateX(-6%);
	}
	body {
	overflow: hidden;
	}
	.adaptive-text {
	font-size: 16px;
	justify-content: center;
	}
	@media (max-width: 600px) {
	.adaptive-text {
		font-size: 14px; 
	}
	}
	.Adaptive-text-Note {
	font-size: 13px;
	justify-content: center;
	}
	@media (max-width: 600px) {
	.Adaptive-text-Note {
		font-size: 9px;
	}
	}
	.small-header {
	padding-top: 1px; /* Adjust as needed */
	padding-bottom: 1px; /* Adjust as needed */
	}
	body {
	background-color: black !important;
	}
	.orange-border-dotted {
	border: 0.1px dotted rgb(210, 210, 210) !important;
	border-radius: 0.1px;
	}
</style>
